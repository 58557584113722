import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Accordion } from '~/components/Accordion'
import { Link } from '~/components/Link'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './FooterLinks.module.css'
import { FooterLinksProps } from './FooterLinks.types'

export const FooterLinks = ({ footerLinkCategories }: FooterLinksProps) => {
  const panels = footerLinkCategories.map((linkCategory, index) => {
    return {
      name: `footer_links_${index}`,
      header: (
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyLargeBold}>
          <span className='inline-block text-white tracking-normal lg:tracking-body-regular lg:text-sm lg:leading-4'>
            {linkCategory.categoryTitle}
          </span>
        </Typography>
      ),
      content: (
        <>
          {linkCategory.links.map((link, lindex) => {
            return (
              <Link
                key={lindex}
                href={link.href}
                target={link.openInNewWindow ? '_blank' : '_self'}
                className='block text-white mt-2'>
                <Typography
                  tag={TypographyTag.p}
                  variant={TypographyVariant.BodyRegular}>
                  <span className='block tracking-normal leading-6'>
                    {link.title}
                  </span>
                </Typography>
                <VisuallyHidden>{link.title}</VisuallyHidden>
              </Link>
            )
          })}
        </>
      ),
    }
  })

  return (
    <div className='text-white'>
      <Accordion
        theme={{
          root: 'flex lg:!hidden flex-col gap-y-6',
          item: 'border-0',
          control: '!bg-transparent p-0',
          icon: styles.accordionIcon,
          content_inner: 'px-0 pb-0 pt-2',
        }}
        multiple
        panels={panels}
      />
      <div
        className={classNames(
          `hidden lg:grid grid-cols-${footerLinkCategories.length}`,
          styles.footerLinksContainer
        )}>
        {panels.map((panel, index) => {
          return (
            <div key={index} className='flex flex-col'>
              <div className='mb-4'>{panel.header}</div>
              <div
                className={classNames('flex flex-col', styles.footerLinksList)}>
                {panel.content}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
