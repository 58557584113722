import React from 'react'
import { Accordion as BaseAccordion, AccordionItem } from '@overdose/components'
import classNames from 'classnames'
import styles from './Accordion.module.css'

import { AccordionProps } from './Accordion.types'

export const Accordion = ({
  panels,
  variant = 'default',
  theme,
  multiple,
  onChange,
  backgroundColor,
  borderRadius,
  value,
  defaultValue,
}: AccordionProps) => {
  return (
    <BaseAccordion
      theme={theme}
      style={{ backgroundColor, borderRadius }}
      variant={variant}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      multiple={multiple}>
      {panels?.map((subItem, index) => {
        return !subItem.content ? null : (
          <AccordionItem
            theme={{
              ...theme,
              control: classNames(styles.accordionItem, theme?.control),
            }}
            style={{ backgroundColor }}
            key={index}
            name={subItem.name}
            header={subItem.header}>
            {subItem.content}
          </AccordionItem>
        )
      })}
    </BaseAccordion>
  )
}
