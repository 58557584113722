import React from 'react'
import { VisuallyHidden } from '@overdose/components'
import { IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import { Button } from '../Button'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './Banner.module.css'
import { BannerProps } from './Banner.types'

const Banner = ({
  logoImage,
  desktopImage,
  tabletImage,
  mobileImage,
  title,
  description,
  button,
}: BannerProps) => {
  return (
    <div className={styles.container}>
      <Image
        className='hidden lg:block object-cover'
        src={desktopImage?.src}
        addSrcSet={false}
        sources={
          <>
            <source
              srcSet={getImageSrc(tabletImage?.src, '720')}
              media='(max-width: 1024px)'
            />
            <source
              srcSet={getImageSrc(mobileImage?.src, '400')}
              media='(max-width: 768px)'
            />
          </>
        }
        alt={desktopImage?.altText}
        fill
        sizes='1280px'
      />

      <div className='absolute py-6 px-5 md:px-12 4xl:px-20 md:py-10 flex flex-col md:flex-row md:justify-between md:items-center w-full h-full top-0 gap-6 md:gap-10'>
        <div className='flex flex-col md:flex-row gap-6 md:gap-10 md:items-center'>
          {logoImage?.src && (
            <div className={styles.logo}>
              <Image
                src={getImageSrc(logoImage?.src, '160')}
                alt={logoImage?.altText}
                fill
                sizes='(min-width: 1080px) 156px, 134px'
                style={{ objectFit: 'contain' }}
              />
            </div>
          )}
          <div className='flex flex-col gap-1 md:gap-2'>
            {title && (
              <Typography
                tag={TypographyTag.h6}
                variant={TypographyVariant.Heading6}
                className={styles.title}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegularExtraLineHeight}
                className={classNames(styles.description, 'text-white')}>
                {description}
              </Typography>
            )}
          </div>
        </div>
        {button && (
          <Button
            status='primary'
            href={button?.href}
            icon={<IconArrowRight size={24} className={styles.iconSize} />}
            iconAlignment='right'
            theme={{
              root: classNames(styles.btnContainer, 'rounded'),
            }}>
            {button.title}
            <VisuallyHidden>{button?.accessibleTitle}</VisuallyHidden>
          </Button>
        )}
      </div>
    </div>
  )
}

export default Banner
