import React from 'react'
import { Image } from '~/components/Image'
import styles from './FooterBackground.module.css'
import { FooterBackgroundProps } from './FooterBackground.types'

const FooterBackground = ({ leftImage, rightImage }: FooterBackgroundProps) => {
  return (
    <div className={styles.container}>
      {leftImage?.src && (
        <div className='absolute h-full mix-blend-multiply left-0 bottom-0 hidden md:block'>
          <Image
            className='w-full h-full object-cover'
            src={leftImage?.src}
            alt={leftImage?.altText}
            width={leftImage?.width}
            height={leftImage?.height}
          />
        </div>
      )}
      {rightImage?.src && (
        <div className='absolute h-full mix-blend-multiply right-0 top-0 hidden md:block'>
          <Image
            className='w-full h-full object-cover'
            src={rightImage?.src}
            alt={rightImage?.altText}
            width={rightImage?.width}
            height={rightImage?.height}
          />
        </div>
      )}
    </div>
  )
}

export default FooterBackground
