import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Link } from '~/components'
import Banner from '~/components/Banner/Banner'
import FooterBackground from '~/components/FooterBackground/FooterBackground'
import { FooterLinks } from '~/components/FooterLinks'
import { Icon } from '~/components/Icon'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import { usePage } from '~/hooks'
import jaycarLogo from '~/public/images/jaycar-logo.svg'
import rtmLogo from '~/public/images/rtm-logo.svg'
import styles from './Footer.module.css'
import { FooterProps } from './Footer.types'

const NZ_DOMAIN_KEY = 'jaycar.co.nz'

export const Footer = ({
  width = 'wide',
  footerLinkCategories,
  socialMediaPlatforms,
  acceptedCardsImages,
  abn,
  termsAndConditions,
  callCentreHoursTitle,
  callCentreHoursPhoneNumber,
  callCentreHours,
  currencyCode,
  tradeMarkText,
  footerBackgroundTexture,
  banner,
}: FooterProps) => {
  const page = usePage()

  const zendeskEnv =
    page?.countryCode?.toLowerCase() === 'au'
      ? process.env.NEXT_PUBLIC_ZENDESK_ID_AU
      : process.env.NEXT_PUBLIC_ZENDESK_ID_NZ

  const showNzPrefix =
    typeof window !== 'undefined' &&
    window.location.href.includes(NZ_DOMAIN_KEY)

  return (
    <footer
      className={classNames(styles.footer, 'relative z-30', {
        [styles.zendeskMobileFooter]: zendeskEnv,
      })}
      id='footer'>
      {footerBackgroundTexture && (
        <div className='absolute inset-0'>
          <FooterBackground {...footerBackgroundTexture} />
        </div>
      )}
      <SectionContainer
        width={width}
        innerClassName='py-6 lg:py-14'
        wrapperClassName={styles.wrapper}>
        {banner && <Banner {...banner} />}
        <div
          className={classNames(
            'flex flex-col lg:flex-row lg:justify-between lg:pt-14 pb-8 lg:pb-10 relative',
            styles.linksContainer
          )}>
          <div className={styles.linkWrapper}>
            <FooterLinks footerLinkCategories={footerLinkCategories} />
          </div>
          <div
            className={classNames(
              'text-white mt-6 lg:mt-0',
              styles.callCentreContainer
            )}>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyLargeBold}
              className={styles.callCentreTitle}>
              {callCentreHoursTitle}
            </Typography>
            <div
              className={classNames(
                'flex flex-col gap-y-2 mt-4',
                styles.callCenterList
              )}>
              <Link
                href={`tel:${callCentreHoursPhoneNumber.replace(/\s/g, '')}`}>
                <Typography
                  tag={TypographyTag.p}
                  variant={TypographyVariant.BodyRegularExtraLineHeight}
                  className='tracking-normal'>
                  Ph. {callCentreHoursPhoneNumber}
                </Typography>
              </Link>
              {callCentreHours.map((hour, index) => {
                return (
                  <Typography
                    key={index}
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyRegularExtraLineHeight}
                    className='tracking-normal'>
                    {hour.label}
                    <br />
                    {hour.time}
                  </Typography>
                )
              })}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-col lg:flex-row items-center lg:justify-between px-9 lg:px-0 lg:pb-8 relative',
            styles.socialMediaPlatformsContainer
          )}>
          <div className='flex items-center gap-x-6 mb-8 lg:mb-0'>
            {socialMediaPlatforms.map((social, index) => {
              return (
                <Link key={index} href={social.url} target='_blank'>
                  <Icon iconName={social.iconName} size={32} color='white' />
                  <VisuallyHidden>{social.brand}</VisuallyHidden>
                </Link>
              )
            })}
          </div>
          <div className='flex flex-wrap justify-center lg:justify-end items-center gap-4'>
            {acceptedCardsImages.map((card, index) => {
              return (
                <div key={index} className='relative h-8'>
                  <Image
                    src={getImageSrc(card?.src, '32')}
                    addSrcSet={false}
                    width={card.width}
                    height={card.height}
                    alt={card.altText}
                    className='object-cover'
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className='w-full border-t border-white opacity-50 relative' />
        <div className='lg:flex items-center gap-x-10 pt-8 text-white relative'>
          <div className='flex items-center justify-center mb-10 lg:mb-0'>
            <Link title='Go to home page' href='/'>
              <Image
                src={jaycarLogo}
                alt='Jaycar Logo'
                width={183}
                height={49}
                className={classNames('hidden', styles.jaycarLogo)}
              />
              <Image
                src={rtmLogo}
                alt='RTM Logo'
                width={199}
                height={48}
                className={classNames('hidden', styles.rtmLogoDesktop)}
              />
              <Image
                src={rtmLogo}
                alt='RTM Logo'
                width={133}
                height={32}
                className={classNames('hidden', styles.rtmLogoMobile)}
              />
            </Link>
          </div>
          <div className='flex items-center justify-between lg:justify-start flex-1 lg:gap-x-10 mb-4 lg:mb-0'>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}>
              {' '}
              © {new Date().getFullYear()} {tradeMarkText}
            </Typography>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}>
              {showNzPrefix ? 'NBN' : 'ABN'} {abn}
            </Typography>
          </div>
          <div className='flex items-center justify-between lg:justify-start gap-x-6 lg:gap-x-10'>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}>
              {currencyCode}
            </Typography>
            {termsAndConditions.map((term, index) => {
              return (
                <Link
                  key={index}
                  href={term.href}
                  target={term.openInNewWindow ? '_blank' : '_self'}
                  className='text-white'>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyRegularExtraLineHeight}>
                    {term.title}
                  </Typography>
                  <VisuallyHidden>{term.title}</VisuallyHidden>
                </Link>
              )
            })}
          </div>
        </div>
      </SectionContainer>
    </footer>
  )
}
